import React from "react";
import Main from "../static-components/html/Main";
import PrivacyContainer from "../containers/shared/PrivacyContainer";

export default function LegalPage(){
    return (
        <Main title="Privacy Policy">
            <PrivacyContainer />
        </Main>
    );
}