import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CardChecklist, ShieldFillCheck } from "react-bootstrap-icons";
import TabsNav from "../../components/buttons/TabsNav";
import StandardPageContainer from "../../components/layout/StandardPageContainer";
import { appConfig } from "../../libs/shared/app-config";
import terms from "../../components/legal/LegalVersions";

export default function PrivacyContainer() {

    function renderNav() {
        return (
            <TabsNav items={
                [
                    {text: "Terms & conditions", url: "/legal", iconCmp: <CardChecklist className="me-3" />},
                    {text: "Privacy policy", url: "/privacy", iconCmp: <ShieldFillCheck className="me-3" />}
                ]
            } />
        )
    }

    return (
        <StandardPageContainer navbarComponent={renderNav()} title={"Legal"} navAboveTitle={false}>
            <Container>
                <Row>
                    <Col>
                        <span className="text-muted">Last updated: {terms.privacy[appConfig.PRIVACY_TERMS_VERSION].date}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {terms.privacy[appConfig.PRIVACY_TERMS_VERSION].fnc()}
                    </Col>
                </Row>
            </Container>
        </StandardPageContainer>
    )
}